import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import ContentWrapper from "../../styles/contentWrapper"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  margin: 50px auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin: 100px auto 50px;
  }
  background: ${({ theme }) => theme.colors.background};
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding: 0 2rem;
    }
  }
  .nav-link {
    padding: 0;
    position: relative;
    cursor: pointer;
    &:hover: {
      color: #0056b3;
    }
    &::before {
      transition: 200ms ease-out;
      height: 0.1563rem;
      content: "";
      position: absolute;
      width: 0%;
      bottom: -0.75rem;
    }
    &:hover::before {
      width: 100%;
      background-color: #0056b3;
    }
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const GoHome = styled.div`
  position: relative;
  z-index: 10;
  padding: 2rem 0 0;
  font-size: ${({ size }) => (size ? size : "1.75rem")};
  font-weight: 400;
  color: #007bff;
  :hover {
    color: #007bff;
  }
  /* Disable effects when sidebar is open */
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
`

const NotFoundSection = () => {
  const gControls = useAnimation()

  useEffect(() => {
    gControls.start({
      opacity: 1,
      y: 0,
      transition: { delay: 0.4 },
    })
  }, [gControls])

  return (
    <StyledSection>
      <StyledContentWrapper>
        <motion.div initial={{ opacity: 0, y: 20 }} animate={gControls}>
          <Container>
            <h1>404</h1>
            <h1>Page not found</h1>
            <GoHome onClick={() => window.history.back()} size="2rem">
              <Link className="nav-link" aria-label="home">
                Go back
              </Link>
            </GoHome>
          </Container>
        </motion.div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

export default NotFoundSection
