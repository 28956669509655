import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useWindowSize } from "../hooks"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentWrapper from "../styles/contentWrapper"
import NotFoundSection from "../components/sections/notFoundSection"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
  }
`

const NotFoundPage = () => {
  const dimensions = useWindowSize()
  const [screen, setScreen] = useState(null)
  useEffect(() => {
    if (dimensions) {
      setScreen(dimensions.windowWidth)
    }
  }, [dimensions])
  return (
    <Layout windowWidth={screen < 1200}>
      <SEO
        title="404: Not found"
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <StyledSection>
        <StyledContentWrapper>
          <NotFoundSection />
        </StyledContentWrapper>
      </StyledSection>
    </Layout>
  )
}

export default NotFoundPage
